import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import KielcePopup from "components/KielcePopup"

import {
  Apartments,
  Nav,
  Baner,
  CallToAction,
} from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-sikorskiego"

const Investment = ({ location }) => {
  const title = "Sikorski Residence"

  return (
    <Layout
      location={location}
      seo={{
        title: "Sikorski Residence",
        description: "",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Kielce", url: "/en/flats/kielce" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} />
      <Nav title={title} investmentRaw="rezydencja_sikorskiego" />
      <Apartments
        investment="Sikorski Residence"
        investmentRaw="rezydencja_sikorskiego"
      />
      <Baner />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Sikorski Residence"
        investmentRaw="rezydencja_sikorskiego"
      />
      {/* <KielcePopup /> */}
    </Layout>
  )
}

export default Investment
