/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useState, useEffect } from "react"

import Form from "./components/Form"

import { useLocale } from "context/locale"

const ContactForm = ({ formType, investment, investmentRaw }) => {
  const { t } = useLocale()
  const [isDefault, setIsDefault] = useState(null)

  const data = [
    {
      investment: null,
      investmentRaw: null,
      contact: {
        email: "kielce@trustinvestment.pl",
        phone: "+48 600 900 500",
      },
    },
    {
      investment: "Krakowska Rogatka",
      investmentRaw: "krakowska_rogatka",
      contact: {
        email: "kielce@trustinvestment.pl",
        phone: "+48 600 900 500",
      },
    },
    {
      investment: "Rezydencja Centrum",
      investmentRaw: "rezydencja_centrum",
      contact: {
        email: "gliwice@trustinvestment.pl",
        phone: "+48 600 700 603",
      },
    },
    {
      investment: "Stacja Kopernik",
      investmentRaw: "stacja_kopernik",
      contact: {
        email: "czestochowa@trustinvestment.pl",
        phone: "+48 600 700 063",
      },
    },
    {
      investment: "Plaza City",
      investmentRaw: "plaza_city",
      contact: {
        email: "czestochowa@trustinvestment.pl",
        phone: "+48 600 700 063",
      },
    },
    {
      investment: "Panorama Struga",
      investmentRaw: "panorama_struga",
      contact: {
        email: "radom@trustinvestment.pl",
        phone: "+48 600 700 630",
      },
    },
    {
      investment: "Rezydencja Świerkowa",
      investmentRaw: "rezydencja_swierkowa",
      contact: {
        email: "radom@trustinvestment.pl",
        phone: "+48 600 700 630",
      },
    },
    {
      investment: "Ultradom",
      investmentRaw: "ultradom",
      contact: {
        email: "radom@trustinvestment.pl",
        phone: "+48 600 700 630",
      },
    },
  ]

  useEffect(() => {
    setIsDefault(data?.some(item => item.investmentRaw === investmentRaw))
  }, [])

  return (
    <section className="contact-form">
      <span className="anchor" id="contact"></span>
      <div className="container-fluid">
        <p className="contact-form__header-label">{t("Kontakt")}</p>
        <h2 className="contact-form__header">{t("Biuro sprzedaży")}</h2>

        <div className="contact-form__content">
          <div className="row">
            <div className="col-md-5">
              <p className="contact-form__content-sub-header contact-form__mobile-hidden">
                {t("napisz do nas")}
              </p>
              {data
                .filter(item =>
                  isDefault
                    ? item.investmentRaw === investmentRaw
                    : item.investmentRaw === null
                )
                .map((item, index) => (
                  <div key={index}>
                    <a
                      className="contact-form__content-header contact-form__mobile-hidden"
                      href={`mailto:${item.contact.email}`}
                    >
                      {item.contact.email}
                    </a>
                    <p className="contact-form__content-sub-header">
                      {t("zadzwoń")}
                    </p>
                    <a
                      className="contact-form__content-header"
                      href={`tel:${item.contact.phone}`}
                    >
                      {item.contact.phone}
                    </a>
                  </div>
                ))}

              <div className="contact-form__person contact-form__mobile-hidden">
                <p className="contact-form__content-sub-header">
                  {t("spotkajmy się")}
                </p>
                {investmentRaw === "rezydencja_centrum" ? (
                  <address>
                    {t(
                      "Parking przy Gliwickim Centrum Handlowym, wejście od ul. Dworcowej"
                    )}
                    <br />
                    44-110 {t("Gliwice")}
                  </address>
                ) : investmentRaw === "panorama_struga" ||
                  investmentRaw === "rezydencja_swierkowa" ||
                  investmentRaw === "ultradom" ? (
                  <address>
                    {t("ul.")} {t("Struga 60")}
                    <br />
                    26-600 {t("Radom")}
                  </address>
                ) : investmentRaw === "stacja_kopernik" ||
                  investmentRaw === "plaza_city" ? (
                  <address>
                    {t("ul.")} {t("Łódzka 52")} ({t("parking")})
                    <br />
                    42-217 {t("Częstochowa")}
                  </address>
                ) : (
                  <address>
                    {t("Al.")} {t("Solidarności 34")}
                    <br />
                    25-323 {t("Kielce")}
                  </address>
                )}
                <p>
                  <strong>{t("Godziny pracy")}:</strong>
                  {t("pn")} – {t("pt")}: 10 – 18
                </p>
              </div>
            </div>

            <div className="col-md-6 offset-md-1">
              <span className="anchor" id="contact-form" />
              <p className="contact-form__content-sub-header">
                {t("napisz do nas")}
              </p>
              <h3 className="contact-form__content-header">
                {t("Formularz kontaktowy")}
              </h3>
              <Form formType={formType} investment={investment} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
