import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/panorama-struga"

const Investment = ({ location }) => {
  const title = "Панорама Струга"

  return (
    <Layout
      location={location}
      seo={{
        title: "Панорама Струга",
        image: withPrefix("/ps-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Радом", url: "/ua/kvartyry/radom" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} />
      <Nav
        title={title}
        investment="Panorama Struga"
        investmentRaw="panorama_struga"
      />
      <Apartments
        investment="Panorama Struga"
        investmentRaw="panorama_struga"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Panorama Struga"
        investmentRaw="panorama_struga"
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export default Investment
