import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import {
  InvestmentHero,
  AboutSingle,
  LocationSingle,
  Apartments,
} from "page_components/office"

import offices from "data/offices"

const Offices = ({ data, location }) => {
  const investment = "Krakowska Rogatka"
  const investmentRaw = "krakowska_rogatka"

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Biura i lokale", url: "/biura-i-lokale/" }}
      />
      <InvestmentHero
        title={investment}
        city="Kielce"
        address_preffix="al."
        address="Miodowicza 1"
        area_min="48"
        area_max="90"
        locales="5"
        deadline="II kwartał"
        deadline_year="2026"
        status="w sprzedaży"
        logo={require("assets/images/logo-krakowska-rogatka.svg").default}
        image={require("assets/images/offices/hero-image-kr.jpg").default}
      />
      <AboutSingle
        investmentRaw={investmentRaw}
        image={require("assets/images/offices/about-image-kr.jpg").default}
        header="Gdzie historia spotyka się z nowoczesnością"
        description="Krakowska Rogatka to dwa budynki inspirowane tradycyjną architekturą w połączeniu z nowoczesnym komfortem tworzą przestrzeń przyjazną do życia. Panoramiczne okna pozwolą cieszyć się pięknymi widokami, a mieszkania będą dobrze doświetlone. Nasza inwestycja to połączenie miejskiego zgiełku z enklawą spokoju, jaką są pobliskie parki oraz rezerwaty przyrody. Lokalizacja w centrum miasta zapewnia bardzo dobry dostęp do dobrze rozwiniętej komunikacji miejskiej."
        icons={[
          {
            icon: require("assets/images/offices/about-icon-1-kr.svg").default,
            title: "Topowa lokalizacja",
          },
          {
            icon: require("assets/images/offices/about-icon-2-kr.svg").default,
            title: "Perfekcyjna komunikacja",
          },
          {
            icon: require("assets/images/offices/about-icon-3-kr.svg").default,
            title:
              "W sąsiedztwie infrastruktura miejska, sportowa i tereny zielone",
          },
        ]}
      />
      <LocationSingle
        investmentRaw={investmentRaw}
        description="Inwestycja Krakowska Rogatka położona jest u zbiegu ulic Wojska Polskiego i Miodowicza, w bliskim sąsiedztwie Parku im. Stanisława Staszica oraz Rezerwat przyrody ‘Kadzielnia’. Niemal centralne położenie zapewnia nie tylko wygodny dostęp placówek edukacyjnych, sklepów czy restauracji, ale również do malowniczych terenów zielonych, idealnych na relaksujące spacery. Od Rynku i głównego miejskiego deptaku przy ul. Sienkiewicza inwestycję dzieli jedynie 800 metrów."
        listing={
          offices?.filter(item => item?.investment === investmentRaw)[0]
            ?.location_list
        }
        map={require("assets/images/offices/location-map-kr.svg").default}
      />
      <Apartments
        apartmentList={data?.allWpOfficeKrakowskaRogatka?.nodes}
        investmentRaw={investmentRaw}
      />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeKrakowskaRogatka(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Offices
