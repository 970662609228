import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

// import Popup from "../Popup"

const rewards = [
  {
    label: "Główna nagroda:",
    prize: "10 000 zł",
  },
  {
    label: "II  miejsce:",
    prize: "3 000 zł",
  },
  {
    label: "III miejsce:",
    prize: "2 000 zł",
  },
]

const details = [
  {
    label: "Zadanie konkursowe",
    description:
      "<p><strong>Stwórz projekt koncepcyjny wspólnej aranżacji wnętrz części wspólnych budynku wielorodzinnego wraz z zagospodarowaniem terenu wokół obiektu</strong></p><p>Składanie prac konkursowych:</p><p>Pracę konkursową wyślij na adres e-mail: <a href='mailto:office@trustinvestment.pl'>office@trustinvestment.pl</a></p><p>lub</p><p>do siedziby firmy Trust&nbsp;Investment&nbsp;SA, ul.&nbsp;Robotnicza&nbsp;1, 6&nbsp;piętro.</p><p>Więcej informacji w regulaminie konkursu.</p>",
  },
  [
    {
      label: "Termin składania prac",
      description: "<p><strong>15.10.2024 - 19.01.2025r.</strong></p>",
    },
    {
      label: "Termin zadawania pytań",
      description: "<p><strong>15 - 30.09.2024r.</strong></p>",
    },
  ],
]

const logos = [
  require("assets/images/logo.svg").default,
  require("assets/images/competition/politechnika-swietokrzyska.png").default,
  require("assets/images/competition/wydzial-budownictwa-i-architektury.png")
    .default,
  require("assets/images/competition/ktipa-u.png").default,
  require("assets/images/competition/lpuipsz.png").default,
]

const Content = () => {
  return (
    <>
      <section className="competition-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-5 col-lg-6">
              <div className="competition-content__wrapper">
                <div className="competition-content__header">
                  <h1>KONKURS</h1>
                  <h2>Stwórz przestrzeń, która łączy</h2>
                  <p>
                    Jesteś studentem kierunku Architektura Wydziału Budownictwa
                    i Architektury w Politechnice Świętokrzyskiej?
                  </p>
                  <p>
                    Weź udział w naszym konkursie i&nbsp;stwórz przyjazną
                    przestrzeń dla mieszkańców Kielc!
                  </p>
                </div>
                <div className="competition-content__image competition-content__image--mobile">
                  <img
                    src={
                      require("assets/images/competition/competition-image.png")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="competition-content__reward">
                  {rewards.map((reward, index) => (
                    <div
                      className={`competition-content__reward-item${
                        index === 0
                          ? " competition-content__reward-item--main"
                          : ""
                      }`}
                      key={index}
                    >
                      <h4>{reward.label}</h4>
                      <h3>{reward.prize}</h3>
                    </div>
                  ))}
                </div>
                <div className="competition-content__details">
                  {details.map((detail, index) => (
                    <div
                      className={`competition-content__details-item ${
                        Array.isArray(detail) ? " multi" : ""
                      }`}
                      key={index}
                    >
                      {Array.isArray(detail) ? (
                        detail.map((item, index) => (
                          <div key={index}>
                            <h4>{item.label}:</h4>
                            {item.description ? (
                              <div
                                className="competition-content__details-description"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <>
                          <h4>{detail.label}:</h4>
                          {detail.description ? (
                            <div
                              className="competition-content__details-description"
                              dangerouslySetInnerHTML={{
                                __html: detail.description,
                              }}
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className="competition-content__files">
                  <h4>Wszystkie niezbędne materiały do pobrania:</h4>
                  <div className="competition-content__files-row">
                    <div>
                      <a
                        className="competition-content__files-download"
                        href={withPrefix("/regulamin-konkursu_19.12.2024.pdf")}
                        target="_blank"
                        rel="nofollow noreferrer"
                        download
                      >
                        Regulamin
                      </a>
                    </div>
                    <div>
                      <a
                        className="competition-content__files-download"
                        href={withPrefix("/materialy-do-konkursu.zip")}
                        target="_blank"
                        rel="nofollow noreferrer"
                        download
                      >
                        Materiały
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-lg-6">
              <div className="competition-content__image competition-content__image--desktop">
                <img
                  src={
                    require("assets/images/competition/competition-image.png")
                      .default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <ul className="competition-content__logos">
            {logos.map((item, index) => (
              <li key={index}>
                <img src={item} alt="" />
              </li>
            ))}
          </ul>
          <div className="competition-content__contact">
            <h4>W razie dodatkowych pytań zapraszamy do kontaktu</h4>
            <p>
              <a href="tel:+48600500560">+48 600 500 560</a>
            </p>
          </div>
        </div>
      </section>

      {/* <Popup /> */}
    </>
  )
}

export default Content
