import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import {
  InvestmentHero,
  AboutSingle,
  LocationSingle,
  Apartments,
} from "page_components/office"

import offices from "data/offices"

const Offices = ({ data, location }) => {
  const investment = "Sky Trust II: Atelier"
  const investmentRaw = "sky_trust_ii_offices"

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Biura i lokale", url: "/biura-i-lokale/" }}
      />
      <InvestmentHero
        title={investment}
        city="Kielce"
        address_preffix="al."
        address="Solidarności"
        area_min="35"
        area_max="623"
        locales="25"
        deadline="IV kwartał"
        deadline_year="2026"
        status="w sprzedaży"
        logo={require("assets/images/logo-sky-trust.svg").default}
        image={require("assets/images/offices/hero-image-st2.jpg").default}
      />
      <AboutSingle
        investmentRaw={investmentRaw}
        image={require("assets/images/offices/about-image-st2.jpg").default}
        header="Odważny i ambitny projekt w topowej lokalizacji."
        description="Harmonijnie wkomponowany w spokojną aurę kieleckiej codzienności, dbający o lokalne potrzeby funkcjonalne miejskiej przestrzeni.<br/><br/>Sky Trust to innowacyjne przedsięwzięcie urbanistyczne łączące funkcje mieszkalne, komercyjne i rekreacyjne, odpowiadając na potrzeby lokalnej społeczności oraz wspiera rozwój lokalnego biznesu. To więcej niż kompleks budynków – to nowoczesne centrum życia miejskiego, które wzbogaci Kielce, oferując mieszkańcom nowe możliwości i podnosząc jakość życia w mieście. Inwestycja ta to emanacja ogromnego potencjału drzemiącego w Kielcach."
        icons={[
          {
            icon: require("assets/images/offices/about-icon-1-st2.svg").default,
            title: "Topowa lokalizacja",
          },
          {
            icon: require("assets/images/offices/about-icon-2-st2.svg").default,
            title: "Najwyższy budynek w regionie",
          },
          {
            icon: require("assets/images/offices/about-icon-3-st2.svg").default,
            title: "Projekt wnętrz by Paprocki Brzozowski",
          },
        ]}
      />
      <LocationSingle
        investmentRaw={investmentRaw}
        description="Dwa kilometry od Rynku, ulicy Sienkiewicza czy Zalewu Kieleckiego. 500 metrów od Galerii Echo, Kina Helios, tuż obok wydziałów Uniwersytetu Jana Kochanowskiego. Na miejscu sklepy, szkoły, komunikacja miejska, a nieopodal węzeł komunikacyjny dróg krajowych 73 i 74 Kraków - Kielce - Warszawa. Sky Trust to centrum życia lokalnego z fenomenalnym połączeniem z Polską i całym światem."
        listing={
          offices?.filter(item => item?.investment === investmentRaw)[0]
            ?.location_list
        }
        map={require("assets/images/offices/location-map-st2.svg").default}
      />
      <Apartments
        apartmentList={data?.allWpOfficeSkyTrust2?.nodes}
        investmentRaw={investmentRaw}
      />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeSkyTrust2(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Offices
