import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import {
  InvestmentHero,
  AboutSingle,
  LocationSingle,
  Apartments,
} from "page_components/office"

import offices from "data/offices"

const Offices = ({ data, location }) => {
  const investment = "Rezydencja Centrum"
  const investmentRaw = "rezydencja_centrum"

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Biura i lokale", url: "/biura-i-lokale/" }}
      />
      <InvestmentHero
        title={investment}
        city="Gliwice"
        address_preffix="ul."
        address="Nasyp 2c"
        area_min="24"
        area_max="39"
        locales="10"
        deadline="II kwartał"
        deadline_year="2025"
        status="w sprzedaży"
        logo={require("assets/images/logo-rezydencja-centrum.svg").default}
        image={require("assets/images/offices/hero-image-rc.jpg").default}
      />
      <AboutSingle
        investmentRaw={investmentRaw}
        image={require("assets/images/offices/about-image-rc.jpg").default}
        header="Kwintesencja życia w mieście"
        description="Budynek zaprojektowano z zachowaniem rezydencyjnego charakteru, a także z troską o każdy najdrobniejszy detal, dzięki czemu zyskał on swoją tożsamość miejską, która będzie aktualna przez kolejne lata. Kameralny VI piętrowy budynek posiada zaledwie 53 starannie zaprojektowane mieszkania. Miejska architektura nadaje bryle szykownego i wielkomiejskiego charakteru, zachowując jednak zwiewną prezencję przywołującą na myśl rezydencje znane z południowych zakątków Europy.  "
        icons={[
          {
            icon: require("assets/images/offices/about-icon-1-rc.svg").default,
            title: "Topowa lokalizacja",
          },
          {
            icon: require("assets/images/offices/about-icon-2-rc.svg").default,
            title: "W centrum nauki i biznesu",
          },
          {
            icon: require("assets/images/offices/about-icon-3-rc.svg").default,
            title: "Rezydencjalny charakter",
          },
        ]}
      />
      <LocationSingle
        investmentRaw={investmentRaw}
        description="Rezydencja Centrum to prawdziwe serce Gliwic. Usytuowanie przy ul. Nasyp gwarantuje bycie w samym środku miejskich wydarzeń, ale też, dzięki sąsiedztwu Drogowej Trasy Średnicowej zapewnia szybkie połączenie z innymi miastami w aglomeracji."
        listing={
          offices?.filter(item => item?.investment === investmentRaw)[0]
            ?.location_list
        }
        map={require("assets/images/offices/location-map-rc.svg").default}
      />
      <Apartments
        apartmentList={data?.allWpOfficeRezydencjaCentrum?.nodes}
        investmentRaw={investmentRaw}
      />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Offices
