import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import {
  InvestmentHero,
  AboutSingle,
  LocationSingle,
  Apartments,
} from "page_components/office"

import offices from "data/offices"

const Offices = ({ data, location }) => {
  const investment = "Browar Hugo Scobel"
  const investmentRaw = "hugo_scobel"

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Biura i lokale", url: "/biura-i-lokale/" }}
      />
      <InvestmentHero
        title={investment}
        city="Gliwice"
        address="Plac Piastów"
        area_min="27"
        area_max="116"
        locales="13"
        deadline="IV kwartał"
        deadline_year="2026"
        status="w sprzedaży"
        logo={require("assets/images/logo-browar-hugo-scobel.svg").default}
        image={require("assets/images/offices/hero-image-hs.jpg").default}
      />
      <AboutSingle
        investmentRaw={investmentRaw}
        image={require("assets/images/offices/about-image-hs.jpg").default}
        header="Styk, komfort i innowacje"
        description="Wyrafinowana architektura w minimalistycznym stylu oraz udogodnienia na miarę komfortowej miejskiej rezydencji tworzą nietuzinkowe ramy dla wysokiej jakości życia. Zaprojektowana, by wybiegać w przyszłość oraz sprostać wymaganiom pod względem technologii przyjaznych dla codziennego życia, troski o naturalne środowisko i wygodę mieszkańców."
        icons={[
          {
            icon: require("assets/images/offices/about-icon-1-hs.svg").default,
            title: "Topowa lokalizacja",
          },
          {
            icon: require("assets/images/offices/about-icon-2-hs.svg").default,
            title: "Tereny zielone",
          },
          {
            icon: require("assets/images/offices/about-icon-3-hs.svg").default,
            title:
              "W sąsiedztwie infrastruktura miejska i doskonała komunikacja",
          },
        ]}
      />
      <LocationSingle
        investmentRaw={investmentRaw}
        description="W samym sercu Gliwic, gdzie miejski styl życia łączy się doskonale z autentycznym klimatem dawnego browaru. W stylowej śródmiejskiej enklawie, możesz cieszyć się miastem w pełnym wydaniu o krok od domu"
        listing={
          offices?.filter(item => item?.investment === investmentRaw)[0]
            ?.location_list
        }
        map={require("assets/images/offices/location-map-hs.svg").default}
      />
      <Apartments
        apartmentList={data?.allWpOfficeHugoScobel?.nodes}
        investmentRaw={investmentRaw}
      />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeHugoScobel(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Offices
