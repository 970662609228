import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

const Breadcrumbs = ({
  title,
  subSubParent,
  subParent,
  parent,
  withBack,
  floorParam,
  buildingParam,
  viewParam,
  isCompetition,
  isLight,
}) => {
  const { t } = useLocale()

  const urlBack =
    subParent?.url && parent?.url
      ? t(`${subParent.url}/${parent.url}/`)
      : t("/")

  return (
    <section
      className={`breadcrumbs${
        isCompetition ? " breadcrumbs--competition" : ""
      }${isLight ? " breadcrumbs--light" : ""}`}
    >
      <div className="container-fluid">
        <div
          className={`breadcrumbs__wrapper${
            withBack ? " breadcrumbs__wrapper--back" : ""
          }`}
        >
          <div className="breadcrumbs__inner">
            <Link to={t("/")}>{t("Strona główna")}</Link>
            <span>&gt;</span>
            {subSubParent && (
              <>
                <Link to={t(`${subSubParent?.url}/`)}>
                  {t(subSubParent?.label)}
                </Link>
                <span>&gt;</span>
              </>
            )}
            {subParent && (
              <>
                <Link to={t(`${subParent?.url}/`)}>{t(subParent?.label)}</Link>
                <span>&gt;</span>
              </>
            )}
            {parent && (
              <>
                <Link
                  to={
                    withBack
                      ? t(`${subParent?.url}/${parent?.url}/`)
                      : t(parent?.url)
                  }
                >
                  {t(parent?.label)}
                </Link>
                <span>&gt;</span>
              </>
            )}
            <span>{title}</span>
          </div>
          {withBack && (
            <Link
              to={`${urlBack}${
                floorParam !== undefined ? `?floor=${floorParam}` : ""
              }${viewParam !== undefined ? `?view=list` : ""}${
                buildingParam !== undefined ? `&building=${buildingParam}` : ""
              }`}
              className="breadcrumbs__back"
            >
              &lt; {t("powrót")}
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default Breadcrumbs
