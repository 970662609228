import "./styles.scss"

import React from "react"

const Grid = () => {
  return (
    <section className="friends-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3>
              Dołącz do naszego Programu Poleceń i spraw, by Twoi bliscy
              zamieszkali obok Ciebie.
            </h3>
            <p>
              Na polecaniu Trust Investment zyskują wszystkie strony. Twoi
              znajomi zamieszkają w świetnym miejscu, zaprojektowanym w
              przemyślany sposób, pełnym udogodnień w komfortowej lokalizacji
              oraz otrzymają rabat na zakup mieszkania. Ty zyskasz wdzięczność
              na lata oraz gratyfikację finansową.
            </p>
          </div>
          <div className="col-md-5 offset-md-1">
            <img
              src={
                require("assets/images/friends/friends-grid-image.png").default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
