import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-swierkowa"

const Investment = ({ location }) => {
  const title = "Смерекова Резиденція"

  return (
    <Layout
      location={location}
      seo={{
        title: "Смерекова Резиденція",
        description:
          "Ідеальне місце для сімей. 133 квартири в 10 хвилинах від центру Радома, площею 25-74 м2. Безпосередньо від авторитетного забудовника.",
        image: withPrefix("/rsk-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Радом", url: "/ua/kvartyry/radom" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} />
      <Nav
        title={title}
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      <Apartments
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export default Investment
