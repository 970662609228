import { navigate } from "gatsby"
import "./styles.scss"

import React, { useState } from "react"

const PopupST = () => {
  const [isOpen, setIsOpen] = useState(true)

  return isOpen ? (
    <div className="popup-st">
      <div className="popup-st__wrapper">
        <div className="popup-st__inner">
          <img src={require("./images/popup-ST-I.jpg").default} alt="" />
          <button
            className="popup-st__cta popup-st__cta--left"
            type="button"
            onClick={() => {
              navigate("/mieszkania/kielce/sky-trust/#apartments")
              setIsOpen(false)
            }}
          >
            Sprawdź ostatnie wolne mieszkanie
          </button>
          <button
            className="popup-st__cta popup-st__cta--right"
            type="button"
            onClick={() =>
              navigate("/mieszkania/kielce/sky-trust-ii/#apartments")
            }
          >
            Zobacz Sky Trust etap II
          </button>
        </div>
      </div>
      <button className="popup-st__backdrop" onClick={() => setIsOpen(false)}>
        Zamknij
      </button>
    </div>
  ) : null
}

export default PopupST
