import "./styles.scss"

import React from "react"

const How = () => {
  const steps = [
    "Poleć nasze mieszkania swojemu znajomemu lub rodzinie.",
    "Jeśli Twój znajomy zdecyduje się na zakup i podpisze akt notarialny, oboje otrzymacie nagrody!",
  ]

  return (
    <section className="friends-how">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <h2>Jak to działa?</h2>
            <div className="friends-how__steps">
              {steps?.map((item, index) => (
                <div>
                  <span>{index + 1}</span>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="w-100" />

          <div className="col-lg-6">
            <h2>Co zyskujesz?</h2>
            <div className="friends-how__header">3 000 zł</div>
            <p>za każde skuteczne polecenie</p>
          </div>

          <div className="col-lg-6">
            <div className="friends-how__divider" />
            <h2>Co zyskuje Twój znajomy?</h2>
            <div className="friends-how__header">Rabat 1%</div>
            <p>za każde skuteczne polecenie</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default How
