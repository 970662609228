import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-centrum"

const Investment = ({ data, location }) => {
  const title = "Резиденція Центр"
  const allApartments = data.allWpApartmentRezydencjaCentrum.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Резиденція Центр",
        image: withPrefix("/rc-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Глівіце", url: "/ua/kvartyry/hlivitse" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} apartmentList={allApartments} />
      <Nav
        title={title}
        investment="Rezydencja Centrum"
        investmentRaw="rezydencja_centrum"
      />
      <Apartments
        apartmentList={allApartments}
        investment="Rezydencja Centrum"
        investmentRaw="rezydencja_centrum"
      />
      <About title={title} />
      <Location />
      <Convenients />
      <Gallery />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Rezydencja Centrum"
        investmentRaw="rezydencja_centrum"
      />
      {/* <Popup type="gliwice" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          type
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
