import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/plaza-city"

const Investment = ({ location }) => {
  const title = "Плаза Сіті"

  return (
    <Layout
      location={location}
      seo={{
        title: `${title}`,
        image: withPrefix("/pc-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Ченстохова", url: "/ua/kvartyry/chenstokhova" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} />
      <Nav title={title} investment="Плаза Сіті" investmentRaw="plaza_city" />
      <Apartments investment="Plaza City" investmentRaw="plaza_city" />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Plaza City"
        investmentRaw="plaza_city"
      />
      {/* <Popup type="czestochowa" /> */}
    </Layout>
  )
}

export default Investment
