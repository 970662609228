import "./styles.scss"

import React from "react"

const Baner = () => {
  const list = [
    "Brak limitu poleceń – więcej znajomych to większe korzyści!",
    "Budujesz wspólnotę sąsiedzką z bliskimi Ci osobami.",
    "Wspólnie tworzycie przyjazną i zgraną okolicę.",
  ]

  return (
    <section className="friends-baner">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="friends-baner__image">
              <img
                src={
                  require("assets/images/friends/friends-baner-image.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>

          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Dlaczego warto?</h2>
            <ul>
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Baner
