import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-centrum"

const Investment = ({ data, location }) => {
  const title = "Centre Residence"
  const allApartments = data.allWpApartmentRezydencjaCentrum.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Centre Residence",
        image: withPrefix("/rc-og-image.jpg"),
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Gliwice", url: "/en/flats/czestochowa" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} apartmentList={allApartments} />
      <Nav
        title={title}
        investment="Centre Residence"
        investmentRaw="rezydencja_centrum"
      />
      <Apartments
        apartmentList={allApartments}
        investment="Centre Residence"
        investmentRaw="rezydencja_centrum"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Centre Residence"
        investmentRaw="rezydencja_centrum"
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          type
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
  }
`

export default Investment
