/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import {
  Apartments,
  Nav,
  Baner,
  News,
  CallToAction,
} from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-sikorskiego"

const Investment = ({ location }) => {
  const title = "Rezydencja Sikorskiego"

  return (
    <Layout
      location={location}
      seo={{
        title: "Rezydencja Sikorskiego",
        image: withPrefix("/rs-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Kielce", url: "/mieszkania/kielce" }}
        subParent={{ label: "Mieszkania", url: "/mieszkania" }}
      />
      <Hero title={title} />
      <Nav title={title} investmentRaw="rezydencja_sikorskiego" />
      <Apartments
        investment="Rezydencja Sikorskiego"
        investmentRaw="rezydencja_sikorskiego"
      />
      <Baner />
      <News investment="rezydencja_sikorskiego" city="Kielce" />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Rezydencja Sikorskiego"
        investmentRaw="rezydencja_sikorskiego"
      />
      {/* <Popup type="kielce" /> */}
    </Layout>
  )
}

export default Investment
