import React from "react"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Grid, How, Baner, File } from "page_components/friends"

const Friends = ({ location }) => {
  const title = "Mieszkaj z przyjaciółmi!"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
    >
      <HeaderBackground
        title={title}
        header={title}
        description="Wyobraź sobie życie w sąsiedztwie swoich znajomych lub rodziny – wspólne chwile, wsparcie na co dzień, a do tego... dodatkowe korzyści!"
        background={
          require("assets/images/friends/friends-header-image.jpg").default
        }
      />
      <Grid />
      <How />
      <Baner />
      <File />
    </Layout>
  )
}

export default Friends
